import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/Image';

import classes from './CaseImage.scss';

function CaseImage({ image, alt, children }) {
  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>

      <div className={classes.wrapper}>
        <Image className={classes.image} image={image} alt={alt} />
      </div>
    </div>
  );
}

CaseImage.propTypes = {
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  alt: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CaseImage;
