import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import CaseImage from 'blocks/CaseImage';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';

const OnsWerk = ({ data }) => {
  const { item } = data;

  return (
    <Layout>
      <Seo
        image={item.titleImage}
        title={item.seoTitle}
        description={item.seoDescription}
      />

      <Title
        image={item.titleImage}
        alt={item.titleHeading}
        subtitle={item.titleSubheading}
        title={item.titleHeading}
      >
        {item.titleText}
      </Title>

      <Section>
        <Image image={item.aboutImage} alt={item.aboutHeading}>
          <Heading size="xl" as="h2">
            {item.aboutHeading}
          </Heading>

          <Text>
            <span dangerouslySetInnerHTML={{ __html: item.aboutText }} />
          </Text>
        </Image>
      </Section>

      <Section color="primary-light" style={{ overflow: 'hidden' }}>
        <CaseImage
          image={item.solutionImage}
          alt={item.solutionHeading}
          position="right"
        >
          <Heading size="xl" as="h2" color="white">
            {item.solutionHeading}
          </Heading>

          <Text color="white">
            <span dangerouslySetInnerHTML={{ __html: item.solutionText }} />
          </Text>
        </CaseImage>
      </Section>

      <Section>
        <Image image={item.resultImage} alt={`Resultaten ${item.company}`}>
          <Heading size="xl" as="h2">
            {item.resultHeading ?? 'Resultaten'}
          </Heading>

          <Text spacing="lg">
            <span dangerouslySetInnerHTML={{ __html: item.resultText }} />
          </Text>

          <Button
            as="a"
            color="tertiary"
            href={item.websiteLink}
            target="_blank"
            spacing="lg"
          >
            Bekijk {item.company} website
          </Button>

          {item.resultTags && (
            <Text>
              <span dangerouslySetInnerHTML={{ __html: item.resultTags }} />
            </Text>
          )}
        </Image>
      </Section>

      <ContactCta />
    </Layout>
  );
};

OnsWerk.propTypes = {
  data: PropTypes.shape({
    item: PropTypes.shape({
      seoTitle: PropTypes.string.isRequired,
      seoDescription: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      websiteLink: PropTypes.string.isRequired,
      titleSubheading: PropTypes.string.isRequired,
      titleHeading: PropTypes.string.isRequired,
      titleText: PropTypes.string.isRequired,
      titleImage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      aboutHeading: PropTypes.string.isRequired,
      aboutText: PropTypes.string.isRequired,
      aboutImage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      solutionHeading: PropTypes.string.isRequired,
      solutionText: PropTypes.string.isRequired,
      solutionImage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      resultText: PropTypes.string.isRequired,
      resultImage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      resultHeading: PropTypes.string.isRequired, // eslint-disable-line react/forbid-prop-types
      resultTags: PropTypes.string.isRequired, // eslint-disable-line react/forbid-prop-types
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query casesItem($slug: String!) {
    item: cases(slug: { eq: $slug }) {
      seoTitle
      seoDescription
      company
      websiteLink
      titleSubheading
      titleHeading
      titleText
      titleImage {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      aboutHeading
      aboutText
      aboutImage {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        relativePath
      }
      solutionHeading
      solutionText
      solutionImage {
        sharpImage: childImageSharp {
          fixed(width: 800, height: 660) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
        publicURL
      }
      resultText
      resultImage {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      resultHeading
      resultTags
    }
  }
`;

export default OnsWerk;
